// ie
import 'core-js';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// custom
import { httpGet, httpPost,HttpRequest,HttpPut,HttpDelete,httpGetNoLog,httpPostNoLog } from './api/index'
import api from './api/api.js';
import util from './utils';

// 全局组件
import '@/components/index';

// 全局样式
import './styles/index.css';
import './styles/animate.css';
import './styles/htmlCss.css';
import './styles/elementRem.css';
import './utils/rem.js';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './element-variables.scss';

import exportFile from './utils/exportFile';
import {
    initData
} from './utils/initData';

import { global } from  './global/index.js';
// 导入预览
import mediaFile from '@/components/mediaFile.vue';
import previewPop from '@/components/previewPop.vue';
// 修改时间范围为单个
import dataTime from '@/components/dataTime.vue';
Vue.component('preview-pop', previewPop);
Vue.component('media-pop', mediaFile);
Vue.component('data-time', dataTime);
Vue.mixin(global)
import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
Vue.use(AOS)

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$util = util;
Vue.prototype.$export = exportFile;
Vue.prototype.$httpGet= httpGet;
Vue.prototype.$httpPost= httpPost;
Vue.prototype.$httpPut= HttpPut;
Vue.prototype.$httpDelete= HttpDelete;
Vue.prototype.$httpGetNoLog= httpGetNoLog;
Vue.prototype.$httpPostNoLog = httpPostNoLog;

initData();

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
});
