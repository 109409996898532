<template>
	<aside class="tisPop popup flexCenter">
		<article class="ptpop_box">
			<header class="ptpop_head zhan">
				<span>{{ tisTitle }}</span>
				<img src="../../../image/dh_b.png" class="ptpop_head_del shou" @click="close()" />
			</header>
			<div class="add_box ">
				<p>{{ tisName }}</p>
				<span v-if="tisTxt.length > 0">{{ tisTxt }}</span>
			</div>
			<div class="flexCenter ptpop_btn">
				<p class="shou " @click="close()">取消</p>
				<p class="shou on" v-if="ifShowQr" @click="confirm()">确定</p>
			</div>
		</article>
	</aside>
</template>
<script>
export default {
	name: 'tisPop',
	props: {
		fromIfShowQr:{
			type:Boolean,
			default:true
		},
		tisTitle: {
			type: String,
			default: '添加人员'
		},
		tisName: {
			type: String,
			default: '是否确定删除'
		},
		tisTxt: {
			type: String,
			default: '请谨慎操作。'
		}
	},
	watch:{
		fromIfShowQr:{
			handler:function(newVal,oldVal){
				this.ifShowQr=newVal
			},
			immediate:true
		}
	},
	data() {
		return {
			ifShowQr:true
		};
	},
	methods: {
		close() {
			this.$emit('close', 'index');
		},
		confirm() {
			this.$emit('confirm', 'index');
		}
	}
};
</script>
<style scoped>
.add_box p {
	font-size: 0.8rem;
	color: #1a1a1a;
	text-align: center;
	padding-bottom: 1rem;
}

.add_box span {
	display: block;
	font-size: 0.7rem;
	color: #666666;
	text-align: center;
}

.add_box{
	padding: 2.15rem 0 2.5rem 0;
}
</style>
